import React, { useEffect, useState } from "react"
import AOS from "aos"
import Layout from "../components/layout"
import Seoinfo from "../components/seoinfo/seoinfo"
import ContactForm from "../components/contactForm/contactForm"
import Hero from "../components/hero"

import Contact1920 from "../images/ContactUS1920.png"
import ContactDesktop from "../images/ContactUS1366.png"
import ContactMobile from "../images/contactMobile.jpg"

import { InternalWrapper } from "../styles/globals/styles.jsx"

import {
  ContactFormContainer,
  Title,
  ContactDiv,
  UnderLineTitle,
  ModalDescription,
  ContactContainer,
} from "../styles/pages/contact.jsx"
import Modal from "../components/Modal/index.jsx"

const Contact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  }
  const image = {
    desktop: ContactDesktop,
    desktop1920: Contact1920,
    desktop1024: ContactMobile,
    mobile: ContactMobile,
  }

  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      })
    },
    [],
    AOS
  )

  return (
    <Layout>
      <Seoinfo
        siteName={"Life Home Therapy"}
        titlePage={"Contact Us"}
        description={
          "Get in touch with the Life Home Therapy Team to design a personalized plan that allows you to recover quality of life."
        }
        keywords={
          "therapy services, in-home services, in-home assistance, physical therapy, occupational therapy, speech-language pathology, home therapy, home therapy services"
        }
        type={"website"}
        url={"hometherapy.life/contact-us"}
        imageFacebook={"/seo/contact.png"}
      />
      <ContactFormContainer>
        <Hero
          image={image}
          title="CONTACT US"
          text="We are just a call or message away. Ready to help!"
          alt="Older woman smiling while being hugged by nurse (in progress)"
        />
        <ContactContainer>
          <InternalWrapper data-aos="fade">
            <ContactDiv>
              <Title>SEND US A MESSAGE</Title>
              <UnderLineTitle />
            </ContactDiv>
            <ContactForm onClickModal={handleModalToggle} />
          </InternalWrapper>
        </ContactContainer>
      </ContactFormContainer>
      <Modal isOpen={isModalOpen} onClose={handleModalToggle}>
        <ModalDescription>
          <span style={{ fontWeight: "bold" }}>Privacy Notice: </span>
          When you opt-in to receive SMS messages from us, we want to assure you
          that we respect your privacy. We do not share or sell your phone
          number or any personal information associated with your opt-in for the
          purposes of SMS or marketing purposes. Your trust is important to us,
          and we use your information solely for the purpose of sending you
          relevant SMS messages as requested. If you have any questions or
          concerns, feel free to contact us at{" "}
          <span style={{ color: "#0000FF", textDecoration: "underline" }}>
            hello@hometherapy.life
          </span>
        </ModalDescription>
      </Modal>
    </Layout>
  )
}

export default Contact
