import React from "react"
import styled from "styled-components"
import { TABLET_800 } from "../../styles/globals/sizes"
import { CloseButton, ModalContent, ModalWrapper } from "./styles"

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}

export default Modal
