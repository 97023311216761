import styled from "styled-components"
import { TABLET_800 } from "../../styles/globals/sizes"

export const ModalWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000093;
  flex-direction: row;
`

export const ModalContent = styled.div`
  width: 90%;
  height: auto;
  position: relative;
  margin: 15% auto;
  padding: 34px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;

  @media (max-width: ${TABLET_800}px) {
    height: auto;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #7f7f7f;
`
